<template>
  <div id="orders">
    <tmpl_header />

    <div id="reviews" class="container">
      <sidebar act="reviews" />

      <div class="main">
        <section
          v-if="!counts || counts.count0 == 0"
          class="card"
          style="padding: 50px 40px"
        >
          <div class="empty">暂无商品评价</div>
        </section>

        <template v-if="counts && counts.count0 > 0">
          <div class="tabs">
            <ul>
              <li :class="{ act: status == 0 }" @click="switchStatus(0)">
                全部订单({{ counts ? counts.count0 : 0 }})
              </li>
              <li :class="{ act: status == 1 }" @click="switchStatus(1)">
                待评价({{
                  counts
                    ? counts.count0 -
                      counts.count1 -
                      counts.count2 -
                      counts.count3 -
                      counts.count4 -
                      counts.count5
                    : 0
                }})
              </li>
              <li :class="{ act: status == 5 }" @click="switchStatus(5)">
                已评价({{ counts ? counts.count4 : 0 }})
              </li>
            </ul>
          </div>

          <div class="card thead">
            <p>商品</p>
            <p>单价</p>
            <p>数量</p>
            <p>收货人</p>
            <p>实付款</p>
            <p>订单状态</p>
            <p>订单操作</p>
          </div>

          <div class="empty" v-if="orders && orders.length == 0">
            没有相关订单
          </div>

          <div class="card tbody" v-for="order in orders" :key="order.orderId">
            <div class="title">
              <span>下单时间：{{ order.createTime }}</span>
              <span>订单号：{{ order.orderOldCode }}</span>
            </div>
            <ul>
              <li v-for="pro in order.goodsMap" :key="pro.goods_info_id">
                <p>
                  <router-link :to="'/goods/view/' + pro.goods_info_id"
                    ><img :src="pro.goods_info_img_id"
                  /></router-link>
                </p>
                <p>
                  <router-link :to="'/goods/view/' + pro.goods_info_id">
                    <strong>{{ pro.goods_info_name }}</strong></router-link
                  >
                </p>
                <p>
                  <strong>￥{{ formatPrice(pro.goods_info_price) }}</strong>
                </p>
                <p>
                  <strong>x{{ pro.goods_info_num }}</strong>
                </p>
              </li>
            </ul>
            <div>
              <p>
                <strong>{{ order.shippingPerson }}</strong>
              </p>
              <p>
                <strong>￥{{ formatPrice(order.orderOldPrice) }}</strong>
                <span>在线支付</span>
              </p>
              <p>
                <strong>{{ orderStatus(order.orderStatus) }}</strong>
              </p>
              <p>
                <a class="btn btn-default" @click="viewOrder(order.orderId)"
                  >订单详情</a
                >
                <router-link to="/center/reviews/edit" class="btn btn-primary"
                  >订单评价</router-link
                >
                <router-link to="/center/reviews/view" class="btn btn-info"
                  >查看评价</router-link
                >
              </p>
            </div>
          </div>

          <pager
            :now="pageNow"
            :show="pageSize"
            :total="pageTotal"
            :rows="rows"
            @onPage="onPage"
            @onChangeRows="onChangeRows"
          ></pager>
        </template>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import pager from "./pager.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      orders: null,
      counts: null,

      search: "",
      status: 0,
      rows: 0,
      pageSize: 10,
      pageNow: 1,
      pageTotal: 1,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
    pager,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/info") },
      });

    api.all([this.loadData(), this.loadStatus()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {
      if (this.status === 0) {
        return this.loadAllData();
      } else if (this.status === 1) {
        return this.loadReviewsData();
      } else {
        return this.loadUnreviewsData();
      }
    },

    loadAllData: function () {
      return api
        .post(apis.center.orders, {
          orderStatus: "",
          orderNo: "",
          pageSize: this.pageSize,
          pageNo: this.pageNow,
        })
        .then((res) => {
          this.orders = res.records;
          this.rows = res.total;
          this.pageNow = res.current;
          this.pageTotal = res.pages;
        });
    },

    loadReviewsData: function () {
      return api.post(apis.center.reviews, {}).then((res) => {
        // console.log(res);
        this.list = res;
        this.rows = 0;
      });
    },

    loadUnreviewsData: function () {
      return api.post(apis.center.unreview, {}).then((res) => {
        // console.log(res);
        this.list = res;
        this.rows = 0;
      });
    },

    loadStatus: function () {
      return api.post(apis.center.orders_count, {}).then((res) => {
        this.counts = res;
      });
    },

    formatPrice(price) {
      let fen = (price * 100) % 100;
      return Math.floor(price) + "." + (fen < 10 ? "0" : "") + fen;
    },

    orderStatus(status) {
      return apis.orderStatus(status);
    },

    orderOperates(status) {
      return apis.orderOperates(status);
    },

    onPage(e) {
      this.pageNow = e.page;
      this.loadData();
    },

    onChangeRows(e) {
      this.pageSize = e.rows;
      this.loadData();
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./orders.less");
@import url("./reviews.less");
</style>
